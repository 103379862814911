<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'

import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import UserIcon from '@lahaus-roomie/roomie/src/assets/icons/user.svg'
import RoomieOverlay from '@lahaus-roomie/roomie/src/components/RoomieOverlay/index.vue'
import RoomieDivider from '@lahaus-roomie/roomie/src/components/RoomieDivider/index.vue'

import type { Props } from './types'
import NavBarV2 from '@/components/BrandedV2/NavBar/index.vue'

import { useUserStore } from '@/stores/userStore'

import './_index.scss'

const { userLabel, isAuthenticated } = useUserStore()

defineProps<Props>()

const emit = defineEmits(['on-side-menu-click'])

const animationClass = ref('')
const opacity = ref('opacity-100')

const onSideMenuClick = () => {
  animationClass.value = 'slide-out'
  opacity.value = 'opacity-0'

  setTimeout(() => {
    emit('on-side-menu-click')
  }, 750)
}

const disableScroll = () => {
  document.body.classList.add('stop-scrolling')
}

const enableScroll = () => {
  document.body.classList.remove('stop-scrolling')
}

onMounted(() => {
  disableScroll()
})

onUnmounted(() => {
  enableScroll()
})

</script>

<script lang="ts">
export default {
  name: 'SideMenuMobileV2'
}
</script>

<template>
  <RoomieOverlay
    class="side-menu transition-opacity duration-700 delay-300 ease-out"
    :class="opacity">
    <div
      class="side-menu__holder pt-32 right-0 absolute rounded-tl-xl"
      :class="animationClass">
      <NavBarV2
        :items="items"
        is-mobile
        is-in-side-menu
        @on-side-menu-click="onSideMenuClick" />

      <div class="side-menu__content text-16">
        <ul class="flex-row justify-end mt-24">
          <li
            v-for="(item, index) in items"
            :key="index"
            class="side-menu__item">
            <component
              :is="item.componentConfig.name === 'RoomieButton' ? RoomieButton : item.componentConfig.name"
              :id="item.id"
              :data-lh-id="`navbar-item-${item.id}`"
              v-bind="item.componentConfig.attrs"
              @click="item.componentConfig.attrs.click">
              <span>
                {{ item.label }}
              </span>
            </component>
          </li>

          <div class="my-24">
            <RoomieDivider
              color="carbon-light-300"
              :rounded="false"
              :vertical="false" />
          </div>

          <li
            v-if="isAuthenticated"
            class="global-navbar__item-mobile body-1-m text-pine-600">
            <span
              data-lh-id="navbar-item-user-label"
              class="flex justify-start"
              rel="nofollow">
              <div
                class="flex items-center justify-center ml-4 -mt-4">
                <UserIcon class="global-navbar__icon w-24 h-24 fill-carbon-800 pointer-events-none" />
              </div>

              {{ userLabel }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </RoomieOverlay>
</template>
