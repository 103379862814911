<script setup lang="ts">
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import SearchIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/search.svg'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import LocationAutocomplete from '@/components/BrandedV2/LocationAutocomplete/index.vue'
import useRealEstateSearch from '@/components/BrandedV2/RealEstateSearch/use-search'

const { locale } = useI18n()

const emit = defineEmits(['filters-change', 'residential-complex-selected'])

const {
  searchTerm,
  onSubmit,
  suggestionSelected
} = useRealEstateSearch('/venta/propiedades', emit)
</script>

<script lang="ts">
export default {
  name: 'RealEstateSearchNavBar'
}
</script>

<template>
  <div class="real-estate-search-nav-bar">
    <form
      class="w-full flex flex-row gap-0 items-center"
      @submit.prevent="onSubmit">
      <LocationAutocomplete
        v-model="searchTerm"
        class="real-estate-search-nav-bar__autocomplete"
        placeholder="Busca por ciudad, zona o nombre del proyecto"
        :country-code="locale"
        container-class="w-full flex flex-row gap-0"
        input-container-class="w-full"
        :min-chars="3"
        @select="suggestionSelected">
        <RoomieButton
          id="real-estate-search-nav-bar"
          type="submit"
          class="real-estate-search-nav-bar__filter-button"
          variant="accent">
          <RoomieIcon custom-styles="block w-24 h-24 text-pine-600">
            <SearchIcon />
          </RoomieIcon>
        </RoomieButton>
      </LocationAutocomplete>
    </form>
  </div>
</template>

<style src="./_index.scss" lang="scss"></style>
