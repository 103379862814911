<script lang="ts" setup>

import { ref, computed, onMounted } from 'vue'

import { storeToRefs } from 'pinia'
import NavBar from '@/components/NavBar/index.vue'
import NavBarV2 from '@/components/BrandedV2/NavBar/index.vue'
import SideMenuMobile from '@/components/SideMenuMobile/index.vue'
import SideMenuMobileV2 from '@/components/BrandedV2/SideMenuMobile/index.vue'
import { useThemeStore } from '@/stores/useThemeStore'
import { useAppConfigStore } from '@/stores/useAppConfig'
import type { NavBarV2ComponentModule } from '@/components/BrandedV2/NavBar/types'

const { tm, rt } = useI18n()
const { isV2 } = useThemeStore()
const appConfigStore = useAppConfigStore()

const { showRealEstateSearchNavBar } = storeToRefs(appConfigStore)
const isSideMenuOpen = ref(false)

const items = computed(() => (tm('items') as Array<any>).map((item: any) => ({
  id: rt(item.id),
  label: rt(item.label),
  path: rt(item.path),
  class: rt(item.class),
  target: item.target
})))

const itemsV2 = computed(() => (tm('v2.items') as Array<any>).map((item: any) => {
  const label = rt(item.label)
  const componentName = rt(item.component_config.name)
  const componentClass = rt(item.component_config.class)
  const attrs = Object.entries(item.component_config.attrs).map(([key, value]) => {
    let clickHandler: any
    const attrValue: any = rt(value)
    const attr = {
      [key]: attrValue
    }
    if (componentName === 'RoomieButton' && key === 'url') {
      clickHandler = () => {
        return window.open(attrValue, '_blank')
      }
      attr.click = clickHandler
    }

    return attr
  })

  return {
    id: rt(item.id),
    label,
    componentConfig: {
      name: componentName,
      class: componentClass,
      attrs: Object.assign({ 'aria-label': label }, ...attrs)
    }
  } as NavBarV2ComponentModule.Item
}))

const openSideMenu = () => {
  isSideMenuOpen.value = true
}

const closeSideMenu = () => {
  isSideMenuOpen.value = false
}

const scrollTop = ref(0)
const showHeader = ref(true)

const handleScroll = () => {
  const currentY = window.scrollY

  if (currentY >= scrollTop.value) showHeader.value = false
  else showHeader.value = true

  scrollTop.value = currentY
}

onMounted(() => {
  window.addEventListener('scroll', () => handleScroll())
})

</script>

<script lang="ts">
export default {
  name: 'AppHeader'
}
</script>

<template>
  <header
    class="app-header"
    :class="{ '!h-0': !showHeader, 'min-h-max !overflow-y-visible': showRealEstateSearchNavBar}">
    <template v-if="isV2">
      <NavBarV2
        :items="itemsV2"
        is-side-menu-open
        @on-side-menu-click="openSideMenu" />

      <SideMenuMobileV2
        v-if="isSideMenuOpen"
        :items="itemsV2"
        @on-side-menu-click="closeSideMenu" />
    </template>

    <template v-else>
      <NavBar
        :items
        is-side-menu-open
        @on-side-menu-click="openSideMenu" />

      <SideMenuMobile
        v-if="isSideMenuOpen"
        :items
        @on-side-menu-click="closeSideMenu" />
    </template>
  </header>
</template>

<style lang="scss">
.app-header {
  @apply fixed top-0 left-0 w-full bg-white z-30 transition-all duration-300 h-64 overflow-y-hidden;
}

.app-header[data-position="absolute"] {
  @apply absolute;
}

.lh-roomie-v2 {
  .app-header {
    @apply md:min-h-[88px];
  }
}
</style>

<i18n src="./i18n.json" lang="json"></i18n>
